import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import IframeResizer from 'iframe-resizer-react'

import styles from './styles.module.css'

export default function CoverManager(props) {
  return (
    <div className={styles.container}>
      <Container>
        {props.title ? <h2 className={styles.title}>{props.title}</h2> : null}
        <br />
        <IframeResizer
          scrolling={false}
          frameBorder="0"
          log
          src={props.url}
          style={{width: '100%'}}
        />
      </Container>
    </div>
  )
}
